import React from 'react';
import PropTypes from 'prop-types';

import ico1 from '../../images/tbiImageIcon2.png';

//const lorem = 'Lorem Ipsum is simply dummy and typesetting industry.';



class Component extends React.Component {

    render() {

        const info = {
            img: ico1, 
            first: 'Voor vragen met betrekking tot de actie en voor de status van uw inschrijving kunt u ons het beste bereiken op <a href="tel:00319000401135">0900 - 040 11 35</a> (NL) / <a href="tel:003290025370">0900 - 253 70</a> (BE) of via e-mail op <a href="mailto:boschpowertools@promocontact.eu">boschpowertools@promocontact.eu</a>.', 
            second: 'U kunt ons klantenservice team bereiken van maandag t/m vrijdag tussen 08:00 – 17:00 uur.'
        }
        
        const { t } = this.props;
        return (
            <div id="contact_us" className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>{t('Hulp nodig?')}</h1>
                        </div>
                    </div>
                    <div className="row touchHighlight">
                        <div className="col-12 th-box">
                            <div className="thb-icon">
                                <img src={info.img} alt="" />
                            </div>
                            <b>Voor vragen met betrekking tot de actie en voor de status van uw inschrijving kunt u ons het beste bereiken op <a href="tel:00319000401135">0900 - 040 11 35</a> (NL) / <a href="tel:003290025370">0900 - 253 70</a> (BE) of via e-mail op <a href="mailto:boschpowertools@promocontact.eu">boschpowertools@promocontact.eu</a>.</b>
                            <br/><br/>
                            <b>U kunt ons klantenservice team bereiken van maandag t/m vrijdag tussen 08:00 – 17:00 uur.</b>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

Component.propTypes = {
    t: PropTypes.func
};

export default Component;
