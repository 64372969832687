import React, { Component } from 'react';

import Page from '../page';
import Search from '../search';
import BoschContact from '../boschcontact';

export default class extends Component {

    render() {
            const channel = this.props.pageContext.channel;

            if(channel === 'bosch'){
                return (
                    <Page hideHeader={true} hideContact={true} {...this.props}>
                        <Search />
                        <BoschContact />
                    </Page>
                );
            } else {
                return (
                    <Page hideHeader={true} {...this.props}>
                        <Search />
                    </Page>
                );
            } 
    }
};
